$(function () {

  // preloader
  var preloader    = $('.js-preloader'), // селектор прелоадера
      imagesCount  = $('img').length, // количество изображений
      dBody        = $('body'), //обращаемся к body
      percent      = 100 / imagesCount, // количество % на одну картинку
      progress     = 0, // точка отсчета
      imgSum       = 3, // количество картинок
      loadedImg    = 0; // счетчик загрузки картинок

  dBody.css('overflow', 'hidden');
  if (imagesCount >= imgSum && imagesCount > 0) {
    dBody.css('overflow', 'hidden');


    for (var i = 0; i < imagesCount; i++) { // создаем клоны изображений
      var img_copy        = new Image();
      img_copy.src        = document.images[i].src;
      img_copy.onload     = img_load;
      img_copy.onerror    = img_load;
    }

    function img_load () {
      progress += percent;
      loadedImg++;
      if (progress >= 100 || loadedImg == imagesCount) {
          preloader.delay(500).addClass('hidden');
          setTimeout(function(){
            dBody.css('overflow', 'auto');
          }, 1500)
      }
      $('.js-preloaderLogo').css({'width': 100 - progress})
    }
  } else {
    preloader.remove();
    dBody.css('overflow', 'auto');
  }

});
